import {NextPage} from 'next'
import {AppProps} from 'next/app'
import {ReactElement, ReactNode} from 'react'

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function App({Component, pageProps}: AppPropsWithLayout) {
  return <Component {...pageProps}/>
}
